import React from 'react';
import Layout from '../components/Layout';

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Connexion</h1>
          <form action="#">
            <div className="field">
              <label className="label" htmlFor={'login'}>
                Identifiant
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="login"
                  id="name"
                  required
                />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor={'password'}>
                Mot de passe
              </label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="password"
                  id="name"
                  required
                />
              </div>
            </div>

            <div className="field">
              <button className="button is-link" type="submit">
                Valider
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
);
